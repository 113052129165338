import { Dropdown, Space, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import Spacer from '~/components/atoms/Spacer'

import ArrowRight from '~/assets/icons/ArrowRightIcon'
import HomeIcon from '~/assets/icons/HomeIcon'
import Logo from '~/assets/icons/Logo'
import SettingIcon from '~/assets/icons/Setting'
import { ROUTER_PATH } from '~/consts/router'
import { BreadcrumbItem } from '~/redux/reducers/app.slice'
import { logout } from '~/utils/user'

import type { MenuProps } from 'antd/es/menu'

interface HeaderProps {
  breadcrumbs: BreadcrumbItem[]
}

const Header = ({ breadcrumbs }: HeaderProps) => {
  const { t } = useTranslation()
  const location = useLocation()

  const onLogout = () => {
    logout()
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_blank" onClick={onLogout}>
          {t('layout.logout')}
        </a>
      ),
    },
  ]

  // useEffect(() => {
  //   if (!valueSelected) return
  //   setLocalStorage(SELECTED_OFFICE, valueSelected)
  //   dispatch(setFilterProjectList(valueSelected))
  // }, [valueSelected])

  const headerCustomClass = location.pathname === '/create-project' || location.pathname === '/' ? 'border-b' : ''

  return (
    <div className={`flex items-center h-16 bg-white z-[1000] px-4 border-solid border-light-100 ${headerCustomClass}`}>
      <Link to={ROUTER_PATH.PROJECT.LIST} className="p-0 w-7">
        <Logo width="28px" height="28px" />
      </Link>
      <Typography className="ml-3 font-bold text-base text-blue-200">{t('layout.app_name')}</Typography>

      {breadcrumbs.length > 0 && (
        <div className="flex items-center ml-8">
          <Link to="/">
            <HomeIcon />
          </Link>
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              <span className="ml-[6px] mr-3">
                <ArrowRight color="#000" />
              </span>
              {breadcrumb.url ? (
                <Link className="font-bold text-sm" to={breadcrumb.url}>
                  {breadcrumb.title}
                </Link>
              ) : (
                <span className="font-bold text-sm">{breadcrumb.title}</span>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      <Spacer />

      <Button type="text" offFocus className="button-navigate" prefixIcon={<SettingIcon isNotImplemented />} isCurrentNotImplemented />
      <Dropdown menu={{ items }}>
        <a>
          <Space>
            <img src="https://bit.ly/dan-abramov" alt="avatar" className="rounded-full w-10" />
          </Space>
        </a>
      </Dropdown>
    </div>
  )
}

export default Header
