export const ROUTER_PATH = {
  PROJECT: {
    LIST: '/',
    DETAIL: '/projects/:idProject/estimates/:idEstimate',
    LIST_ESTIMATE: '/projects/:id/estimates',
    MAPPING: '/projects/:idProject/estimates/:idEstimate/mapping',
  },
  FILE: {
    FILE: '/file',
    PREVIEW: '/file/preview/:documentId',
  },
}
