import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import PrivateRoute from '~/components/organisms/privateRoute'

import { ROUTER_PATH } from '~/consts/router'
import BasicLayout from '~/layout/BasicLayout/index'

import { Suspense } from './suspense'

const ListProjectPage = lazy(() => import('~/features/project/list/ListProjectPage'))
const ListEstimatePage = lazy(() => import('~/features/estimate/listEstimate'))

const BasicLayoutRouter: RouteObject = {
  path: '',
  element: (
    <PrivateRoute>
      <BasicLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: '',
      element: (
        <Suspense>
          <ListProjectPage />
        </Suspense>
      ),
    },
    {
      index: true,
      path: ROUTER_PATH.PROJECT.LIST_ESTIMATE,
      element: (
        <Suspense>
          <ListEstimatePage />
        </Suspense>
      ),
    },
  ],
}

export default BasicLayoutRouter
