import { useRoutes } from 'react-router-dom'

import estimateRouter from '~/routes/estimate'
import PrivateBasicRouter from '~/routes/privateBasic'

import EmptyLayoutRouter from './privateEmpty'

export default function Routes() {
  return useRoutes([PrivateBasicRouter, estimateRouter, EmptyLayoutRouter])
}
