import Header from '@components/molecules/Header'
import React from 'react'
import { Outlet } from 'react-router-dom'

import PageLoading from '~/components/molecules/PageLoading'

import { useAppSelector } from '~/redux/hooks'

const BasicLayout = () => {
  const { breadcrumbs, isLoading } = useAppSelector((state) => state.app)
  return (
    <div className="container-layout">
      <Header breadcrumbs={breadcrumbs} />
      <div className="basic-layout">
        <Outlet />
      </div>
      {isLoading && <PageLoading />}
    </div>
  )
}

export default BasicLayout
