import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface projectInfoType {
  projectInfo: any
}

const initialState: projectInfoType = {
  projectInfo: {},
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectInfo: (state, action: PayloadAction<any>) => {
      state.projectInfo = action.payload
    },
  },
})

// Actions
export const { setProjectInfo } = projectSlice.actions

// Selector
export const projectIn = (state: any) => state.project

// Reducer
export default projectSlice.reducer
