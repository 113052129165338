import axios from 'axios'

import client from '~/api/axiosClient'
import { RawEstimateFormat, SaveEstimateFormatItem } from '~/features/device-mapping/types'
import { Estimate, UpdateEstimateData } from '~/interfaces/estimate'
import { CompanyOffice, CreateProjectEstimateData, Project, ProjectSection, ProjectStaff } from '~/interfaces/project'

// Project list
export const apiGetProject = (request: any) =>
  client.get('/projects', {
    params: request.params,
  })

export const getProjectById = (id: string) => client.get<{ data: Project }>('/projects/' + id)

export const updateProject = (id: string, data: Project) => client.put<{ data: Project }>('/projects/' + id, data)

// Project Estimate List
export const apiGetEstimateList = (request: any) =>
  client.get(`/projectEstimates/${request.id}`, {
    params: request.params,
  })

// Project Estimate
export const apiGetEstimate = (request: any) => client.get(`/projectEstimates/${request.idProject}/${request.idEstimate}`)

// Project Detail
export const apiGetProjectDetail = (request: any) => client.get(`/projects/${request.idProject}/${request.idEstimate}`)

export const apiCreateMemo = (request: any) =>
  client.post(`/projects/${request.idProject}/memo`, {
    content: request.content,
  })

export const apiGetMemo = (request: any) => client.get(`/projects/${request.idProject}/memo`)

// Create Project
export const apiCreateProject = (data: any) => client.post(`/projects`, data)

// Building Mode
export const apiBuildingMode = () => client.get(`/projectBuildingModes`)

// Building Type
export const apiBuildingType = () => client.get(`/projectBuildingTypes`)

// Detail Machine
export const apiDetailMachine = (request: any) =>
  client.get(`/projects/${request.idEstimate}/machine/${request.objectRegistration}`, { params: request.params })

// Project element
export const apiProjectElement = () => client.get<{ data: any[] }>('/projects/element')

// Add device
export const apiAddDevice = (request: any) => client.get(`/projects/element/${request.mainCode}/${request.subCode}`, { params: request.params })

// Add machine submit
export const apiAddMachine = (request: any) => client.post(`/projects/${request.estimateId}/machine`, { body: request.body })

export const getGeographyApi = (search_text: string, access_token: string) =>
  axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search_text}.json`, {
    params: { limit: '1', access_token: access_token, language: 'ja' },
  })

export const getOfficeApi = (request?: any) =>
  client.get(`/offices`, {
    params: request?.params,
  })

export const getCompanyOffices = (propertyNumber: string) => {
  return client.get<{ data: CompanyOffice[] }>(`/companyOffices?f={"property_number":${propertyNumber}}`)
}

export const getProjectSections = (officeId: string, sort?: string, orderBy?: string) => {
  return client.get<{
    data: { data: ProjectSection[] }
  }>(`/projectSections?f={"office_id":"${officeId}"}`, { params: { sort, order_by: orderBy } })
}

export const getProjectStaffs = (sectionNumber?: string | number, sort?: string, orderBy?: string) => {
  return client.get<{ data: { data: ProjectStaff[] } }>(`/projectStaffs/${sectionNumber}`, {
    params: {
      sort,
      order_by: orderBy,
    },
  })
}

export const postProjectEstimate = (data: CreateProjectEstimateData) => {
  return client.post<{ data: { data: ProjectStaff[] } }>(`/projectEstimates`, data)
}

export const deleteProjectEstimate = (request: any) => {
  return client.delete(`/projectEstimates/${request.projectId}`, {
    data: { data: request.estimateIds },
  })
}

export const uploadImportApi = (request: any) => {
  return client.post(`/import/deviceECosts/${request.estimateId}`, request.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const validateDrawingNumber = (request: any) => {
  return client.get(`projects/${request.estimateId}/checkUnique/drawingNumber`, {
    params: request?.params,
  })
}

export const saveEstimateFormat = (estimateId: string, data: { data: SaveEstimateFormatItem[] }) => {
  return client.put(`/projectEstimates/${estimateId}/formats`, data)
}

export const removeEstimateFormat = (estimateId: string, data: { ids: string[] }) => {
  return client.delete(`/projectEstimates/${estimateId}/formats`, { data })
}

export const getProjectOptions = () => {
  return client.get(`/projects/options`)
}

export const getEstimateFormats = (estimateId: string, fileId?: string, draft?: boolean) => {
  return client.get<{ data: RawEstimateFormat[] }>(`/projectEstimates/${estimateId}/formats`, {
    params: {
      file_id: fileId,
      draft: draft ? true : undefined,
    },
  })
}

export const getEstimateById = (projectId: string, estimateId: string) => {
  return client.get<{ data: Estimate }>(`/projectEstimates/${projectId}/${estimateId}`)
}

export const updateEstimate = (estimateId: string, data: UpdateEstimateData) => {
  return client.put(`/projectEstimates/${estimateId}`, data)
}

export const autoCompleted = (officeId?: string | null, search?: string) => {
  return client.get<{ data: Array<{ id: string; name: string }> }>('/projects/autocomplete', { params: { q: search, office_id: officeId } })
}
